const items_offer_data = [
  {
    id: "1ViviGiallo",
    ethPrice: "10",
    usdPrice: "$90,136.10",
    difference: "70%",
    Expiration: "2",
    user: "CocaCola",
  },
  {
    id: "2DB96DB",
    ethPrice: "15.5",
    usdPrice: "$45,458.10",
    difference: "70%",
    Expiration: "2",
    user: "DB96DB",
  },
  {
    id: "3myc_nc",
    ethPrice: "0.9",
    usdPrice: "$2,347.90",
    difference: "98%",
    Expiration: "2",
    user: "myc_nc",
  },
  {
    id: "4MetaRDnA",
    ethPrice: "1.2",
    usdPrice: "$4,568.40",
    difference: "100%",
    Expiration: "5",
    user: "MetaRDnA",
  },
  {
    id: "5Karafuru",
    ethPrice: "0.5",
    usdPrice: "$1,699.10",
    difference: "100%",
    Expiration: "5",
    user: "Karafuru",
  },
  {
    id: "6seekortelur",
    ethPrice: "4.7",
    usdPrice: "$13,966.64",
    difference: "40%",
    Expiration: "5",
    user: "seekortelur",
  },
];

const items_Properties_data = [
  {
    id: 0,
    category: "ACCESSORY",
    product: "Metal headband",
    trait: "3% have this trait",
  },
  {
    id: 1,
    category: "SKIN",
    product: "Dark Brown",
    trait: "8% have this trait",
  },
  {
    id: 2,
    category: "EYES",
    product: "Cyborg",
    trait: "2% have this trait",
  },
  {
    id: 3,
    category: "CLOTH",
    product: "Adidas",
    trait: "7% have this trait",
  },
  {
    id: 4,
    category: "HAIR",
    product: "White Ash",
    trait: "7% have this trait",
  },
  {
    id: 5,
    category: "CHARACTER",
    product: "TSAREVNA",
    trait: "1% have this trait",
  },
  {
    id: 6,
    category: "BACKGROUND",
    product: "CyberPunk",
    trait: "9% have this trait",
  },
  {
    id: 7,
    category: "MOUTH",
    product: "Flower",
    trait: "4% have this trait",
  },
];

const items_activity_data = [
  {
    id: "1ViviGiallo",
    price: "30",
    from: "AD496A",
    to: "Polymorph: MORPH Token",
    date: "1 days ago",
    text: "bid",
  },
  {
    id: "2DB96DB",
    price: ".00510",
    from: "The_vikk",
    to: "Polymorph: MORPH Token",
    date: "1 days ago",
    text: "transfer",
  },
  {
    id: "3myc_nc",
    price: "1.50",
    from: "CryptoGuysNFT",
    to: "Polymorph: MORPH Token",
    date: "2 days ago",
    text: "sale",
  },
];

export { items_offer_data, items_Properties_data, items_activity_data };
