const collection_data = [
  {
    id: 1,
    title: "Montecristo Farms",
    image: "/images/regen_farming/farms.png",
    icon: true,
    amount: "0.031",
    postTime: "2022-07-20",
    postDate: "Last 24 Hours",
    address: "0x88eb46ffa550a55b13965d1739994703d467fc67",
  },
  {
    id: 2,
    title: "Verdant Revival Farms",
    image: "/images/regen_farming/grass.png",
    icon: true,
    amount: "0.029",
    postTime: "2022-07-10",
    postDate: "Last 24 Hours",
    address: "0xef3a76ff3be04bcff322c801dd001e3ae7ee2dc1",
  },
  {
    id: 3,
    title: "New Dawn Organics",
    image: "/images/regen_farming/grain_rows.png",
    icon: false,
    amount: "0.011",
    postTime: "2022-07-08",
    postDate: "Last 24 Hours",
    address: "0xfbee3c6110f59888dad543c5974430c026521662",
  },
  {
    id: 4,
    title: "Biome Boost Agrisciences",
    image: "/images/regen_farming/mushrooms.png",
    icon: true,
    amount: "3,186",
    postTime: "2022-07-01",
    postDate: "Last 24 Hours",
    address: "0x414bb3b5f58493cba58df8c46021e46b4598124a",
  },
  {
    id: 5,
    title: "Flourish Fields Ltd.",
    image: "/images/regen_farming/tall_grass.png",
    icon: false,
    amount: "3,027",
    postTime: "2022-07-05",
    postDate: "Last 24 Hours",
    address: "0x803aabe9e9ea61311a93a7a5befb90c82c061686",
  },
  {
    id: 6,
    title: "Wind And Sea Farms",
    image: "/images/regen_farming/farm3.png",
    icon: false,
    amount: "1,027",
    postTime: "2022-07-05",
    postDate: "Last 24 Hours",
    address: "0xecf07aab2ec76ddb9592e30d501fb73f480d72cf",
  },
];

const collection_item_data = [
  {
    id: "farms",
    title: "Montecristo Farms",
    image: "/images/farmer_avatars/farmer_1.png",
    icon: true,
    creator: "051_Hart",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    amount: "7,080.95",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
  },
  {
    id: "avatar_2",
    title: "Cryptopank",
    image: "/images/avatars/avatar_2.jpg",
    icon: true,
    creator: "Wow Frens",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "6,548,133",
  },
  {
    id: "avatar_3",
    title: "Prince Ape Planet",
    image: "/images/avatars/avatar_3.jpg",
    icon: false,
    creator: "Origin Morish",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "NFT stars",
  },
  {
    id: "avatar_4",
    title: "Hey Mrsmeseks",
    image: "/images/avatars/avatar_4.jpg",
    icon: true,
    creator: "Lazy Panda",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "3,186",
  },
  {
    id: "avatar_5",
    title: "Bored Bunny",
    image: "/images/avatars/avatar_5.jpg",
    icon: false,
    creator: "Crytopank",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "3,027",
  },
  {
    id: "avatar_6",
    title: "Wow Frens",
    image: "/images/avatars/avatar_6.jpg",
    icon: false,
    creator: "Hey Mrsmeseks",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "2,586",
  },
  {
    id: "avatar_7",
    title: "Origin Morish",
    image: "/images/avatars/avatar_7.jpg",
    icon: true,
    creator: "alyxbow",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "2,347.85",
  },
  {
    id: " avatar_8",
    title: "Superdo",
    image: "/images/avatars/avatar_8.jpg",
    icon: true,
    creator: "Wow Frens",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "2,115.71",
  },
  {
    id: "avatar_9",
    title: "NFT stars",
    image: "/images/avatars/avatar_9.jpg",
    icon: false,
    creator: "NFT stars",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "2,027",
  },
  {
    id: "avatar_10",
    title: "Asumitsu",
    image: "/images/avatars/avatar_10.jpg",
    icon: true,
    creator: "Origin Morish",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "1,989.70",
  },
  {
    id: "avatar_11",
    title: "Pank Skull",
    image: "/images/avatars/avatar_11.jpg",
    icon: false,
    creator: "Lazy Panda",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "1,726.70",
  },
  {
    id: "avatar_12",
    title: "Lazy Panda",
    image: "/images/avatars/avatar_12.jpg",
    icon: false,
    creator: "051_Hart",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "1,589.03",
  },
  {
    id: "avatar_13",
    title: "Arcahorizons",
    image: "/images/avatars/avatar_13.jpg",
    icon: true,
    creator: "Crytopank",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "1,157",
  },
  {
    id: "avatar_14",
    title: "Sussygirl",
    image: "/images/avatars/avatar_14.jpg",
    icon: false,
    creator: "Hey Mrsmeseks",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "1,030",
  },
  {
    id: "avatar_15",
    title: "Smilebin",
    image: "/images/avatars/avatar_15.jpg",
    icon: true,
    creator: "alyxbow",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "999.51",
  },
  {
    id: "avatar_16",
    title: "Pankysmoke",
    image: "/images/avatars/avatar_16.jpg",
    icon: false,
    creator: "Wow Frens",
    text: "Unique, fully 3D and built to unite the design multiverse. Designed and styled by Digimental.",
    details: [
      {
        id: "07.2K",
        detailsNumber: "7.2K",
        detailsText: "Items",
      },
      {
        id: "15.3K",
        detailsNumber: "5.3K",
        detailsText: "Owners",
      },
      {
        id: "22.55",
        detailsNumber: "2.55",
        detailsText: "Floor Price",
      },
      {
        id: "317.2K",
        detailsNumber: "17.2K",
        detailsText: "Volume Traded",
      },
    ],
    amount: "875.65",
  },
];

const collection_activity_item_data = [
  {
    id: "farms",
    image: "/images/regen_farming/farms.png",
    title: "Montecristo Farms",
    price: "Purchased 2,000 tons of Carbon Offsets",
    time: "12 weeks ago",
    category: "purchases",
  },
  {
    id: "grass",
    image: "/images/regen_farming/grass.png",
    // image: '/images/products/item_21_sm.jpg',
    title: "Verdant Revival",
    price: "Purchased 120 tons of Carbon Offsets",
    time: "2 months ago",
    category: "purchases",
  },
  {
    id: "grain_rows",
    image: "/images/regen_farming/mushrooms.png",
    // image: '/images/products/item_22_sm.jpg',
    title: "New Dawn Organics",
    price: "Purchased 30,000 tons of Carbon Offsets",
    time: "8 months ago",
    category: "purchases",
  },
  {
    id: "mushrooms",
    image: "/images/regen_farming/mushrooms.png",
    // image: '/images/products/item_24_sm.jpg',
    title: "Biome Boost Agrisciences",
    price: "Purchased 6,000 tons of Carbon Offsets",
    time: "1 year and 2 months ago",
    category: "purchases",
  },
  {
    id: "tall_grass",
    image: "/images/regen_farming/tall_grass.png",
    // image: '/images/products/item_23_sm.jpg',
    title: "Flourish Fields",
    price: "Purchased 5,831 tons of Carbon Offsets",
    time: "1 year and 6 months ago",
    category: "purchases",
  },
];

const collectionCategoryData = [
  {
    parentId: 1,
    name: " Today's Drops",
    collections: [
      {
        id: 1,
        title: "NFT Funny Cat",
        image: "/images/avatars/avatar_1.jpg",
        icon: true,
        amount: "7,080.95",
        postTime: "2022-07-20",
      },
      {
        id: 2,
        title: "Cryptopank",
        image: "/images/avatars/avatar_2.jpg",
        icon: true,
        amount: "6,548,133",
        postTime: "2022-07-10",
      },
      {
        id: 3,
        title: "Prince Ape Planet",
        image: "/images/avatars/avatar_3.jpg",
        icon: false,
        amount: "4,823,927",
        postTime: "2022-07-08",
      },
      {
        id: 4,
        title: "Hey Mrsmeseks",
        image: "/images/avatars/avatar_4.jpg",
        icon: true,
        amount: "3,186",
        postTime: "2022-07-01",
      },
    ],
  },
  {
    parentId: 2,
    name: "Top Sellers",
    collections: [
      {
        id: 5,
        title: "Bored Bunny",
        image: "/images/avatars/avatar_5.jpg",
        icon: false,
        amount: "3,027",
        postTime: "2022-07-05",
      },
      {
        id: 6,
        title: "Wow Frens",
        image: "/images/avatars/avatar_6.jpg",
        icon: false,
        amount: "2,586",
        postTime: "2022-07-08",
      },
      {
        id: 7,
        title: "Origin Morish",
        image: "/images/avatars/avatar_7.jpg",
        icon: true,
        amount: "2,347.85",
        postTime: "2022-07-20",
      },
      {
        id: 8,
        title: "Superdo",
        image: "/images/avatars/avatar_8.jpg",
        icon: true,
        amount: "2,115.71",
        postTime: "2022-07-10",
      },
    ],
  },
  {
    parentId: 3,
    name: "Top Buyers",
    collections: [
      {
        id: 9,
        title: "NFT stars",
        image: "/images/avatars/avatar_9.jpg",
        icon: false,
        amount: "2,027",
        postTime: "2022-07-12",
      },
      {
        id: 10,
        title: "Asumitsu",
        image: "/images/avatars/avatar_10.jpg",
        icon: true,
        amount: "1,989.70",
        postTime: "2022-07-15",
      },
      {
        id: 11,
        title: "Pank Skull",
        image: "/images/avatars/avatar_11.jpg",
        icon: false,
        amount: "1,726.70",
        postTime: "2022-07-16",
      },
      {
        id: 12,
        title: "Lazy Panda",
        image: "/images/avatars/avatar_12.jpg",
        icon: false,
        amount: "1,589.03",
        postTime: "2022-07-14",
      },
    ],
  },
];

export {
  collection_data,
  collection_item_data,
  collection_activity_item_data,
  collectionCategoryData,
};
