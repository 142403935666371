const newseLatterData = [
  {
    id: "1",
    icon: {
      parentBg: "#beaaf7",
      childBg: "rgb(131 88 255) ",
      svg: "icon-wallet",
    },

    title: "Purchase Carbon Credits",
    text: "Once you've set up your account of choice, connect it to Matter by clicking the account button in the top right corner.",
  },
  {
    id: "2",
    icon: {
      parentBg: "#c4f2e3",
      childBg: "rgb(16 185 129)",
      svg: "icon-wallet",
    },

    title: "Create your Listing",
    text: "Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.",
  },
  {
    id: "3",
    icon: {
      parentBg: "#cddffb",
      childBg: "rgb(66 138 248)",
      svg: "icon-gallery",
    },
    title: "Tokenize your Carbon Credits",
    text: "Upload your metadata (image, video, audio, or 3D art), add a title and description, and customize your Carbon Credit's listing.",
  },
  {
    id: "4",
    icon: { parentBg: "#ffd0d0", childBg: "rgb(239 68 68)", svg: "icon-list" },
    title: "Sell your Carbon Credits",
    text: "Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your Carbon Credits!",
  },
];

export { newseLatterData };
