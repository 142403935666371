const Feature_collections_data = [
  {
    id: "0Montecristo Farms",
    bigImage: "/images/regen_farming/farms.png",
    userImage: "/images/farmer_avatars/farmer_1.png",
    userName: "Montecristo_Farms",
    itemsCount: "10K",
    title: "Montecristo Farms",
    category: "Forestry",
    top: true,
    trending: true,
    recent: true,
  },
  {
    id: "1Verdant",
    bigImage: "/images/regen_farming/grass.png",
    userImage: "/images/farmer_avatars/farmer_2.png",
    userName: "Verdant Revival",
    itemsCount: "2.8K",
    title: "Verdant Revival Farms",
    category: "Forestry",
    top: false,
    trending: false,
    recent: false,
  },
  {
    id: "2New Dawn",
    bigImage: "/images/regen_farming/grain_rows.png",
    userImage: "/images/farmer_avatars/farmer_3.png",
    userName: "New_Dawn",
    itemsCount: "8K",
    title: "New Dawn Organics",
    category: "Community",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "3Biome Boost",
    bigImage: "/images/regen_farming/mushrooms.png",
    userImage: "/images/farmer_avatars/farmer_4.png",
    userName: "Biome_Boost",
    itemsCount: "1.5K",
    title: "BIome Boost Agrisciences",
    category: "Renewable",

    top: false,
    trending: true,
    recent: false,
  },
  {
    id: "4Flourish Fields",
    bigImage: "/images/regen_farming/tall_grass.png",
    userImage: "/images/farmer_avatars/farmer_5.png",
    userName: "Flourish_Fields",
    itemsCount: "15K",
    title: "Flourish Fields",
    category: "Renewable",
    top: true,
    trending: false,
    recent: true,
  },
];

export default Feature_collections_data;
