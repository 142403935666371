let apiEndpoint;

if (process.env.NODE_ENV === "production") {
  apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT_PROD;
} else {
  apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT_DEV;
}

const apiConfig = {
  apiEndpoint,
};

export default apiConfig;
