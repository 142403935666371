const trending_category_filter = [
  {
    id: 0,
    text: "all",
  },
  {
    id: 1,
    svg: "world",
    text: "Forestry",
  },

  {
    id: 2,
    svg: "peoples",
    text: "Renewable",
  },
  {
    id: 3,
    svg: "domain",
    text: "Community",
  },
];

const trendingCategoryData = [
  {
    image: "/images/regen_farming/farms.png",
    id: "Flourishing Cat #1800",
    category: "Forestry",
    title: "Flourishing Cat #180",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 1,
    sortPrice: 8.49,
    price: "From 8.49 ETH",
    bidLimit: 8,
    bidCount: 2,
    likes: 15,
    creator: {
      name: "Montecristo Forms",
      image: "/images/farmer_avatars/farmer_1.png",
    },
    owner: {
      name: "Montecristo Farms",
      image: "/images/farmer_avatars/farmer_1.png",
    },
  },
  {
    image: "/images/products/item_4.jpg",
    id: "Amazing NFT art1",
    category: "Collectibles",
    title: "Amazing NFT art",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 2,
    sortPrice: 5.9,
    price: "From 5.9 ETH",
    bidLimit: 7,
    bidCount: 1,
    likes: 188,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_2.png",
    },
  },
  {
    image: "/images/products/item_7.jpg",
    id: "SwagFox#1332",
    category: "domain",
    title: "SwagFox#133",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 3,
    sortPrice: 0.078,
    price: "0.078 ETH",
    bidLimit: 3,
    bidCount: 1,
    likes: 160,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_3.png",
    },
  },
  {
    image: "/images/products/item_6.jpg",
    id: "Splendid Girl3",
    category: "music",
    title: "Splendid Girl",
    nfsw: true,
    lazyMinted: true,
    verified: false,
    addDate: 4,
    sortPrice: 10,
    price: "10 ETH",
    bidLimit: 3,
    bidCount: 2,
    likes: 159,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_4.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_8.jpg",
    id: "Monkeyme#1554",
    category: "photography",
    title: "Monkeyme#155",
    nfsw: false,
    lazyMinted: false,
    verified: true,
    addDate: 5,
    sortPrice: 5,
    price: "From 5 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 32,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_3.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
  {
    image: "/images/products/item_9.jpg",
    id: "Jedidia#1495",
    category: "virtual world",
    title: "Jedidia#149",
    nfsw: true,
    lazyMinted: false,
    verified: true,
    addDate: 6,
    sortPrice: 0.16,
    price: "0.16 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 25,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_6.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_4.png",
    },
  },
  {
    image: "/images/products/item_10.jpg",
    id: "Artof Eve6",
    category: "art",
    title: "Artof Eve",
    nfsw: true,
    lazyMinted: false,
    verified: false,
    addDate: 7,
    sortPrice: 0.13,
    price: "0.13 FLOW",
    bidLimit: 1,
    bidCount: 1,
    likes: 55,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_2.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_7.png",
    },
  },
  {
    image: "/images/products/item_11.jpg",
    id: "Asuna #16497",
    category: "collection",
    title: "Asuna#1649",
    nfsw: false,
    lazyMinted: true,
    verified: true,
    addDate: 8,
    sortPrice: 0.8,
    price: "0.8 ETH",
    bidLimit: 1,
    bidCount: 1,
    likes: 70,
    creator: {
      name: "Sussygirl",
      image: "/images/avatars/creator_8.png",
    },
    owner: {
      name: "Sussygirl",
      image: "/images/avatars/owner_5.png",
    },
  },
];

export { trending_category_filter, trendingCategoryData };
