import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Actions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow } from "../../redux/counterSlice";
import axios from "axios";
import { Network, Alchemy } from "alchemy-sdk";

const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY;

const settings = {
  apiKey: ALCHEMY_API_KEY,
  network: Network.ETH_SEPOLIA,
};

const alchemy = new Alchemy(settings);

const CategoryItem = () => {
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();

  const [currentAccount, setCurrentAccount] = useState();
  const [nfts, setNfts] = useState([]);

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setCurrentAccount(account[0]);
        return account[0];
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const address = await connectWallet();
      alchemy.nft.getNftsForOwner(address).then((response) => {
        console.log(response);
        setNfts([...response.ownedNfts]);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {nfts.map((nft, i) => {
        // const itemLink = image
        //   .split("/")
        //   .slice(-1)
        //   .toString()
        //   .replace(".jpg", "")
        //   .replace(".gif", "");
        return (
          <article key={i}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <Link
                  href={`http://localhost:3000/item/${nft.contract.address}`}
                >
                  <a>
                    <img
                      src={nft?.media[0]?.raw}
                      alt="item 5"
                      className="w-full h-[230px] rounded-[0.625rem] object-cover"
                    />
                  </a>
                </Link>

                <Likes like={50} />

                <div className="absolute left-3 -bottom-3">
                  <div className="flex -space-x-2">
                    <Link href={``}>
                      <a>
                        <Tippy content={<span>creator: AFarmingCompany</span>}>
                          <img
                            src="/images/farmer_avatars/farmer_1.png"
                            alt="creator"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </a>
                    </Link>
                    <Link href={``}>
                      <a>
                        <Tippy content={<span>creator: Delta</span>}>
                          <img
                            src="/images/farmer_avatars/delta_avatar.png"
                            alt="owner"
                            layout="fill"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white bg-white"
                          />
                        </Tippy>
                      </a>
                    </Link>
                  </div>
                </div>
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <Link
                  href={`http://localhost:3000/item/${nft.contract.address}`}
                >
                  <a>
                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {nft.title}
                    </span>
                  </a>
                </Link>

                {/* auction dropdown  */}
                <Auctions_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full " />
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
