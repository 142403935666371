import ArtsCarousel from "../carousel/artCarousel";
import Link from "next/link";
import Image from "next/image";
import Logo from "./../../public/images/logo.png";
import ElipseSvg from "../elipseSvg";
import NewsLetter from "../mailing/Newsletter";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden hidden xl:block">
          <figure className="h-[600px] w-full flex items-center ">
            <ElipseSvg className="h-[630px] sm:h-[560px] md:h-[480px] lg:h-[630px] xl:h-[630px]" />
          </figure>
        </picture>

        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <figure className="h-[630px] w-full">
            <Image
              src="/images/gradient_dark.jpg"
              alt="gradient dark"
              layout="fill"
            />
          </figure>
        </picture>

        <div className="container">
          <div className="mb-12 text-left">
            <h1 className="text-jacarta-700 font-display mb-6 text-5xl dark:text-white lg:text-6xl xl:text-6xl normal-case mt-12 ">
              Embark on a sustainable journey with technology powered by our
              <span className="text-[#7C59F5]"> white glove service</span>
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-lg normal-case">
              We determine the exact carbon footprint of regenerative farming
              practices, enabling carbon credit initiatives to exist on the
              blockchain, fostering a clearer carbon credit marketplace. Matter
              can also broker deals for due diligence and providence to ensure
              validity of carbon credits.
            </p>
            <div className="inline-flex space-x-4">
              {/* <Link href="/create">
                <a className="bg-[#7C59F5] hover:bg-white hover:text-[#7C59F5] hover:border-2 hover:border-color-[#7C59F5] w-36 rounded-md py-3 px-8 text-center font-semibold text-white transition-all">
                  Create
                </a>
              </Link> */}
              <Link href="/collection/explore_collection">
                <a className="text-accent border-2 border-color-[#7C59F5] w-36 rounded-md bg-white py-3 px-8 text-center font-semibold transition-all hover:bg-[#7C59F5] hover:text-white">
                  Explore
                </a>
              </Link>
            </div>
          </div>

          {/* <div className="relative">
            
            <ArtsCarousel />
          </div> */}
          <NewsLetter></NewsLetter>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero;
