import axios from "axios";
import { useState } from "react";
const NewsLetter = () => {
  const [email, setEmail] = useState();

  function submit() {
    axios.post("/api/airtable", { email: email }).then((res) => {
      if (res.status === 200) {
        setEmail("");
        console.log(res.status);
      }
      console.log(res.status);
    });
  }

  return (
    <>
      <p className="text-jacarta-700 mx-auto  max-w-2xl text-center text-lg dark:text-white">
        Join our mailing list!
      </p>

      <div className="mx-auto mt-7 mb-8 max-w-md text-center">
        <form
          className="relative"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <input
            type="email"
            placeholder="Email address"
            value={email}
            className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
            Subscribe
          </button>
        </form>
      </div>
    </>
  );
};

export default NewsLetter;
