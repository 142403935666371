import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import axios from "axios";
import Link from "next/link";
import { Network, Alchemy } from "alchemy-sdk";
import apiConfig from "../../utils/apiConfig";

const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY;

const settings = {
  apiKey: ALCHEMY_API_KEY,
  network: Network.ETH_SEPOLIA,
};

const alchemy = new Alchemy(settings);

const ArtsCarousel = () => {
  const [contractAddresses, setContractAddresses] = useState(null);
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    axios.get(apiConfig.apiEndpoint + "/api/contracts").then((response) => {
      console.log(response);
      setContractAddresses(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contractAddresses && contractAddresses.length > 0) {
      // Create an array of promises to fetch NFTs for all contract addresses
      const promises = contractAddresses.map((address) =>
        alchemy.nft.getNftsForContract(address)
      );

      // Execute all promises
      Promise.all(promises).then((responses) => {
        // Merge all NFT arrays into a single array
        const allNfts = responses.flatMap((response) => response.nfts);
        setNfts(allNfts.slice(0, 5));
      });
    }
  }, [contractAddresses]);
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {nfts.map((nft, i) => {
          return (
            <SwiperSlide key={i}>
              <article>
                <div className="dark:bg-jacarta-700 rounded-lg block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/item/${nft.contract.address}`}>
                      <a>
                        <img
                          src={nft.media[0].raw}
                          alt="item 1"
                          height="430"
                          width="379"
                          layout="responsive"
                          className="swiper-lazy h-[430px] w-full object-cover swiper-lazy-loaded rounded-2.5xl"
                        />
                      </a>
                    </Link>
                  </figure>
                  <div className="p-6">
                    <div className="flex">
                      <Link href={`/item/${nft.contract.address}`}>
                        <a className="shrink-0 mr-4">
                          {/* user image */}
                          <img
                            src={nft.media[0].raw}
                            alt={name}
                            height={40}
                            width={40}
                            className="mr-4 h-10 w-10 rounded-full"
                          />
                        </a>
                      </Link>
                      <div>
                        <Link href={`/item/${nft.contract.address}`}>
                          <a className="block">
                            <span className="font-display hover:text-accent text-jacarta-700 text-lg leading-none dark:text-white">
                              {nft.title}
                            </span>
                          </a>
                        </Link>
                        <Link href={`/item/${nft.contract.address}`}>
                          <a className="text-accent text-2xs ">
                            aFarmingCompany
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default ArtsCarousel;
